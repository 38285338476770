.banner {
    display: flex;

    padding-left: 100px;

    height: 60px;
    width: 100vw;
    
    background: linear-gradient(to top right, var(--finished-task-bg-1) 50%, var(--finished-task-bg-2) 50%);

    font-size: 30px;
    font-weight: 600;
    color: var(--task-text);
    align-items: center;
}

#completedTaskList {
    margin: auto;
    width: 95%;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;

    padding: 20px 8%;
}

.completedTask {
    flex: 0 0 30%;
    margin: 10px;
    height: 60px;

    background: linear-gradient(to top right, var(--finished-task-bg-1) 50%, var(--finished-task-bg-2) 50%);
    border: 5px solid var(--finished-task-border);
    border-radius: 10px;

    cursor: pointer;
    transition: all .2s ease-in-out; 
}

.completedTask p {
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: var(--task-text);
    font-size: 22px;
    font-family: "Inter", Helvetica;
}