.m_App {
  text-align: center;
  height: 100vh;
  background-color: #750037;
  display: flex;
  flex-direction: column;
}

.m_AppContent {
    margin-top: 75px; /*Header height*/
    display: flex;
    flex: 1
}