.m_footerContainer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #750037;
  display: flex;
  align-content: center;
}

.m_footerIcon {
  flex-grow: 1;
  color: white;
  padding: 4px;
}

#m_selectedFooterIcon {
  border-top: 4px solid #D5A704;
}