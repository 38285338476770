#m_header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #750037;
    display: flex;

    height: 75px;

    padding: 10px;
}

#m_navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

#m_navbar #m_mainlogo {
    width: 400px;
    height: 60px; 
    cursor: pointer;
    align-items: center;
}

#m_navbar .m_option {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;

    color: #FAF4EB;
    cursor: pointer;

    padding-bottom: 2px;
}

#m_navbar .m_option:hover {
    text-decoration: underline;
}