#usernameText {
  font-size: 20px;
  font-family: 'Inter';
  font-weight: 600;
  position: relative;
  top: 4px;
  padding-left: 5px;
}

.accountSettings {
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #FFE7C1;
  padding: 0px 11px;
  border-radius: 10px;
  cursor: pointer;
}