#Home {
    padding: 2%;
}

#toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-left: 30px;
    padding-right: 50px;
}

#activeTaskTitle {
    color: var(--title);
    font-size: 32px;
    font-family: "Inter", Helvetica;
}

#activeTaskList {
    margin: auto;
    width: 95%;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

#addTask {
    cursor: pointer;

    margin-right: 20px;
    
    color: var(--title);
    font-size: 24px;
    font-weight: 300;
    font-family: "Inter", Helvetica;

    background:
    linear-gradient(
      to right,
      var(--bar-grad-1),
      var(--bar-grad-2)
    ),
    linear-gradient(
      to right,
      var(--hover-grad-1),
      var(--hover-grad-2),
      var(--hover-grad-3)
    );
    background-size: 100% 3px, 0 3px;
    background-position: 100% 80%, 0 80%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
    transition: all .4s;
}

#addTask:hover {
    transform: scale(1.03);
    background-size: 0 3px, 100% 3px;
}

.activeTask {
    flex: 0 0 30%;
    margin: 10px;
    height: 60px;

    background-color: var(--task-bg);
    border: 5px solid var(--task-border);
    border-radius: 10px;

    cursor: pointer;
    transition: all .2s ease-in-out; 
}

.activeTask:hover {
    border: 5px solid var(--task-hover-border);
}

.activeTask p {
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: var(--task-text);
    font-size: 22px;
    font-family: "Inter", Helvetica;
}