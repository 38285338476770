:root {

    /* Color variables */

    --primary-color: #750037;
    --secondary-color: #D5A704;
    
    --dark-purple: #8B076C9C; /* 0.61 opacity */
    --bright-purple: #8715EB;
    --bright-pink: #EB1997;
    --dark-orange: #C4990C;
    --light-orange: #F3C00D;
    --light-yellow: #FFE7C1;
    --pastel-yellow: #E8E6CC;
    --almost-white: #FAF4EB;
    --lime: #D9F43C;
    --blue-grey: #3E4C7C;
    --black: #000000;
    --white: #FFFFFF;

    /* Coloring variables */
    
    --title: var(--almost-white);

    --bar-grad-1: var(--dark-purple);
    --bar-grad-2: var(--bright-purple);
    --hover-grad-1: var(--pastel-yellow);
    --hover-grad-2: var(--lime);
    --hover-grad-3: var(--dark-orange);

    --task-bg: var(--light-yellow);
    --task-border: var(--bright-pink);
    --task-text: var(--blue-grey);
    --task-hover-border: var(--light-orange);

    --finished-task-bg-1: var(--secondary-color);
    --finished-task-bg-2: var(--light-orange);
    --finished-task-border: var(--light-orange);

    --code-bg: var(--black);
    --code-lang-bg: var(--blue-grey);
    --code-text: var(--almost-white);
    
    --primary-text: var(--almost-white);
  }