.settingsPageContainer {
  display: flex;
  flex-direction: row;
  padding: 2%;
  color: var(--primary-text);
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  padding-right: 96px;
}

.avatar {
  padding-bottom: 16px;
}

.clickableText {
  cursor: pointer;
}

.settingsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.settingsHeader {
  font-size: 28px;
}

.settingsInput {
  display: flex;
  flex-direction: row;
  padding: 8px 0px;
}

.saveButtonContainer {
  display: flex;
  align-items: center;
  padding-top: 8px;
}