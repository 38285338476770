#header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 20px;
}

#navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

#navbar #mainlogo {
    width: 150px;
    cursor: pointer;
    margin-right: 40px;
    margin-left: 10px;
}

#navbar .option {
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;

    color: var(--title);

    margin-left: 60px;
    margin-right: 60px;
    cursor: pointer;

    padding-bottom: 13px;
}

#navbar .option:hover {
    text-decoration: underline;
}