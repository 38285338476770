.taskModal {
    

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 3%;

    height: 85vh;
    width: 100%;
    
    background-color: var(--task-bg);
    border: 5px solid var(--task-border);
    border-radius: 10px;

    overflow: auto;
    
    align-items: center;
}

.taskDescription {
    width: 100%;
}

.taskTitle {
    color: var(--task-text);
    font-size: 36px;
    font-family: "Inter", Helvetica;

    margin-bottom: 25px;
}

.taskContent {
    color: var(--task-text);
    font-size: 22px;
    font-family: "Inter", Helvetica;

    margin-bottom: 25px;
}

.taskContentPar {
    color: var(--task-text);
    font-size: 22px;
    font-family: "Inter", Helvetica;
    white-space: pre-wrap;
    
    margin: 10px 0px 10px 0px;
}

.taskContentLang {
    background: var(--code-lang-bg);
    color: var(--code-text);
    font-size: 18px;
    margin: 10px 0px 0px 0px;
    padding: 5px;
}

.taskContentCode {
    background: var(--code-bg);
    color: var(--code-text);
    font-size: 18px;
    padding: 10px;
    margin: 0px 0px 10px 0px;
}

.taskCompletedButton {
    background: linear-gradient(to top right, var(--finished-task-bg-1) 50%, var(--finished-task-bg-2) 50%);
    border: 5px solid var(--finished-task-border);
    border-radius: 10px;

    color: var(--task-text);
    font-size: 25px;
    font-family: "Inter", Helvetica;

    width: 480px;
    min-width: 200px;
    text-align: center;

    padding: 10px;
    cursor: pointer;
}

.closeIconContainer {
    display: flex;
    flex-direction: row-reverse;
    width: inherit;
}

.closeIcon {
    cursor: pointer;
}