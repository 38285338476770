#m_Home {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

#m_activeTaskTitle {
    width: 100%;

    text-align: left;
    padding: 15px 0px 0px 15px;
    color: black;
    font-size: 25px;
    font-weight: 400;
    font-family: "Inter", Helvetica;
}

#m_activeTaskList {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.m_activeTask {
    width: 100%;
    margin: 5px;
    height: 60px;

    background-color: #FFE7C1;
    border: 5px solid #EB1997;
    border-radius: 10px;

    cursor: pointer;
    transition: all .2s ease-in-out; 
}

.m_activeTask:hover {
    border: 5px solid #F3C00D;
}

#m_addTask {
    background-color: #750037;
    border-radius: 50%;
    aspect-ratio: 1;
    width: 60px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 6px #dce0dd;

    position: fixed;
    bottom: 70px;
    right: 30px;
}

.m_addTaskIcon {
    color: white;
}

.m_activeTask p {
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: #3E4C7C;
    font-size: 22px;
    font-family: "Inter", Helvetica;
}